// extracted by mini-css-extract-plugin
export var root = "About-module--root--oKddO";
export var textPrimary = "About-module--textPrimary--Lz8xx";
export var title = "About-module--title--e-jh2";
export var tagline = "About-module--tagline--uErjf";
export var social = "About-module--social--60FAL";
export var socialIcon = "About-module--socialIcon--Ule0B";
export var profile = "About-module--profile--fL1Zj";
export var profilePic = "About-module--profilePic--acbjH";
export var bio = "About-module--bio--Rln5c";
export var details = "About-module--details--CMem3";
export var item = "About-module--item---jv3f";
export var itemIcon = "About-module--itemIcon--Caruv";
export var itemTitle = "About-module--itemTitle--OAnWB";
export var itemSubtitle = "About-module--itemSubtitle--9GYXu";
export var itemContent = "About-module--itemContent--DpuIy";