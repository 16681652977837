import React from "react";

const Amazon = ({ className }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    className={className}
    aria-label="Amazon"
    viewBox="0 0 24 24"
    role="img"
  >
    <path d="M12.14,2C9.513,2,6.595,2.983,5.981,6.196C5.915,6.538,5.898,6.718,5.898,6.718l3.58,0.362c0,0,0.02-0.291,0.068-0.538 c0.23-1.107,1.167-1.654,2.219-1.654c0.568,0,1.21,0.213,1.546,0.716c0.388,0.562,0.324,1.324,0.324,1.975v0.37 C12.035,8.127,9.947,8.236,8.45,8.887c-1.73,0.74-2.942,2.249-2.942,4.468c0,2.839,1.815,4.27,4.139,4.27 c1.963,0,3.023-0.471,4.538-1.999c0.501,0.718,0.679,1.072,1.596,1.827l0.299,0.251l0.349-0.3c0.551-0.486,1.556-1.358,2.119-1.827 c0.183-0.149,0.339-0.289,0.339-0.289s-0.306-0.381-0.339-0.427c-0.505-0.69-1.022-1.262-1.022-2.543V8.072 c0-1.804,0.121-3.473-1.222-4.715C15.244,2.351,13.482,2,12.14,2z M12.888,10.146c0.249-0.016,0.498,0,0.748,0v0.592 c0,1.065,0.043,1.941-0.499,2.888c-0.439,0.769-1.145,1.234-1.92,1.234c-1.059,0-1.671-0.792-1.671-1.975 C9.546,10.85,11.146,10.259,12.888,10.146z M20.517,16.515c-0.699,0.01-1.518,0.157-2.144,0.592 c-0.192,0.134-0.395,0.327-0.395,0.327s2.708-0.312,2.988,0.043c0.28,0.355-0.749,2.858-0.749,2.858s0.27-0.117,0.45-0.267 c1.173-0.972,1.487-2.988,1.247-3.283C21.795,16.641,21.217,16.505,20.517,16.515z M2,17c2.39,2.474,6.173,4,10,4 c2.73,0,5.657-0.911,7.844-2.509c0.304-0.233,0.535-0.474,0.675-0.698l-0.786,0.299c-2.452,1.03-5.12,1.434-7.544,1.434 C8.596,19.526,4.813,18.621,2,17L2,17z"></path>
  </svg>
);

export default Amazon;
