// extracted by mini-css-extract-plugin
export var root = "ProjectCard-module--root--XVWIX";
export var defaultFont = "ProjectCard-module--defaultFont--9zcbb";
export var header = "ProjectCard-module--header--WUU6I";
export var title = "ProjectCard-module--title--NO1Qc";
export var subtitle = "ProjectCard-module--subtitle--P9Qb4";
export var actions = "ProjectCard-module--actions--EnJSY";
export var media = "ProjectCard-module--media--ea8nY";
export var mediaTitle = "ProjectCard-module--mediaTitle--nKGdK";
export var description = "ProjectCard-module--description--abitx";
export var icon = "ProjectCard-module--icon--+JDaC";